import {
  VStack,
  HStack,
  Switch,
  Text,
  Box,
  useColorMode,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { AiFillSetting, AiOutlineCloseSquare } from 'react-icons/ai';
import { useState } from 'react';
import DevToolsWhiteLabelSelector from '~/utils/whiteLabel/DevToolsWhiteLabelSelector';
import colors from '~/theme/colors';
import DevToolNewSub from '../NewSubscriptionForm/DevToolNewSub';
import StyledButton from '../StyledButton';

const localStorage =
  typeof window !== 'undefined' ? window?.localStorage : undefined;

export const apiOrMockedData = localStorage?.getItem(
  'connected_to_api_or_mocked_data',
) as 'api' | 'mock';

const DevTools = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [showDevMenu, setShowDevMenu] = useState(false);
  const handleDevToolsButton = () => setShowDevMenu(prevValue => !prevValue);
  const pathName =
    typeof window !== 'undefined' ? window?.location?.pathname : '';

  const toggleConnectionMode = () => {
    if (typeof window === 'undefined') return;

    const toggleConnectionTo: 'api' | 'mock' =
      apiOrMockedData === 'mock' ? 'api' : 'mock';

    localStorage?.setItem(
      'connected_to_api_or_mocked_data',
      toggleConnectionTo,
    );
    localStorage?.setItem('advisor_override_id', '');
    window?.location.reload();
  };

  const bgColor = useColorModeValue('gray.500', 'gray.890');
  const borderColor = useColorModeValue(
    'transparent',
    colors.brand.perwinkle['500'],
  );

  const textColor = useColorModeValue('black', 'white');
  const valueColor = useColorModeValue('green', 'orange');

  return (
    <>
      <Box position="absolute" right="3" top="6">
        <StyledButton
          bg="orange"
          borderRadius="0px"
          onClick={handleDevToolsButton}>
          <Icon as={AiFillSetting} color="white" />
        </StyledButton>
      </Box>
      {showDevMenu && (
        <VStack
          bg={bgColor}
          p="4"
          alignItems="start"
          position="absolute"
          border={`2px solid ${borderColor}`}
          spacing="6"
          top="5.5%"
          left="37.5%">
          <Icon
            as={AiOutlineCloseSquare}
            alignSelf="end"
            cursor="pointer"
            color={textColor}
            onClick={handleDevToolsButton}
          />
          {pathName ===
            '/qualified-purchasers/clients/new-subscription-form' && (
            <DevToolNewSub />
          )}
          <DevToolsWhiteLabelSelector />
          <HStack>
            <Text color={textColor}>Toggle Theme:</Text>
            <Text color={valueColor} fontWeight="bold">
              {colorMode === 'light' ? 'Light' : 'Dark'}
            </Text>
            <Switch onChange={toggleColorMode} />
          </HStack>
          <HStack>
            <Text color={textColor}>Toggle API or Mocked Data: </Text>
            <Text color={valueColor} fontWeight="bold">
              {apiOrMockedData === 'mock' ? 'Mocked Data' : 'API'}
            </Text>
            <Switch onChange={toggleConnectionMode} />
          </HStack>
        </VStack>
      )}
    </>
  );
};

export default DevTools;
