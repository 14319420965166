import { HStack, useColorModeValue } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import SearchSelect from 'components/Form/Selects/SearchSelect';
import capitalizeSentence from 'utils/capitalizeSentence';
import { flow, map } from 'lodash/fp';
import StyledButton from '~/components/StyledButton';
import {
  NON_US_ACCOUNT_TYPES_PAYLOADS,
  US_LP_ACCOUNT_TYPES_PAYLOADS,
  US_SPC_ACCOUNT_TYPES_PAYLOADS,
} from './utils/payloads';
import { newSubscriptionDefaults } from '../utils/defaults';
import useNewSubscriptionStore from '../store/newSubscriptionStore';

const DevToolNewSub = () => {
  const { handleSubmit, ...methods } = useForm({});
  const getFormattedFormDataString = (payloadByAccountType: any) =>
    JSON.stringify({
      ...newSubscriptionDefaults,
      ...payloadByAccountType,
    });

  const notUsFormattedTypes = flow(
    Object.entries,
    map(([accountType, payload]) => ({
      value: getFormattedFormDataString(payload),
      label: `SPC (${capitalizeSentence(
        accountType,
        accountType?.length <= 3,
      )}) [Non-US]`,
    })),
  )(NON_US_ACCOUNT_TYPES_PAYLOADS);

  const usOnshoreFormattedTypes = flow(
    Object.entries,
    map(([accountType, payload]) => ({
      label: `LP (${capitalizeSentence(
        accountType,
        accountType?.length <= 3,
      )})`,
      value: getFormattedFormDataString(payload),
    })),
  )(US_LP_ACCOUNT_TYPES_PAYLOADS);

  const usOffshoreFormattedTypes = flow(
    Object.entries,
    map(([accountType, payload]) => ({
      label: `SPC (${capitalizeSentence(
        accountType,
        accountType?.length <= 3,
      )})`,
      value: getFormattedFormDataString(payload),
    })),
  )(US_SPC_ACCOUNT_TYPES_PAYLOADS);

  const subscriptionTypes = [
    ...notUsFormattedTypes,
    ...usOnshoreFormattedTypes,
    ...usOffshoreFormattedTypes,
  ];

  const subscriptionTypesOrderedAlphabetically = subscriptionTypes.sort(
    (accountType1, accountType2) =>
      accountType1.label.localeCompare(accountType2.label),
  );
  const { setNewSubscriptionState } = useNewSubscriptionStore();

  const handleFillForm = (data: any) => {
    if (!data?.dev_tool_account_type) return;

    setNewSubscriptionState({
      ...newSubscriptionDefaults,
      ...JSON.parse(data?.dev_tool_account_type),
    });
  };

  const fillFormButtonBgColor = useColorModeValue(
    'brand.perwinkle.500',
    'brand.perwinkle.500',
  );

  return (
    <FormProvider {...{ handleSubmit, ...methods }}>
      <HStack as="form" onSubmit={handleSubmit(handleFillForm)} spacing={4}>
        <SearchSelect
          options={subscriptionTypesOrderedAlphabetically}
          width="400px"
          label="Select the Account type to pre fill"
          name="dev_tool_account_type"
          filterMachFromStart={false}
        />
        <StyledButton
          type="submit"
          minW="110px"
          height="48px"
          bgColor={fillFormButtonBgColor}>
          Fill form
        </StyledButton>
      </HStack>
    </FormProvider>
  );
};

export default DevToolNewSub;
